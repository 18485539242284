:host {
  display: block;
  font-family: var(--sc-font-sans);
  font-size: var(--sc-checkout-font-size, 16px);
}

.collapse-link {
  display: flex;
  align-items: center;
  gap: 0.35em;
}

.summary__content--empty {
  display: none;
}

.collapse-link__icon {
  width: 18px;
  height: 18px;
  color: var(--sc-order-collapse-link-icon-color, var(--sc-color-gray-500));
}

.item__product + .item__product {
  margin-top: 20px;
}

.empty {
  color: var(--sc-order-summary-color, var(--sc-color-gray-500));
}

.price {
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  transform: translateY(5px);
  transition: var(--sc-input-transition, var(--sc-transition-medium)) visibility ease, var(--sc-input-transition, var(--sc-transition-medium)) opacity ease,
    var(--sc-input-transition, var(--sc-transition-medium)) transform ease;

  &--collapsed {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.summary {
  position: relative;
  user-select: none;
  cursor: pointer;

  .collapse-link__icon {
    transition: transform 0.25s ease-in-out;
  }

  .scratch-price {
    text-decoration: line-through;
    color: var(--sc-color-gray-500);
    font-size: var(--sc-font-size-small);
    margin-right: var(--sc-spacing-xx-small);
  }

  &--open {
    .collapse-link__icon {
      transform: rotate(180deg);
    }
  }
}

::slotted(*) {
  margin: 4px 0 !important;
}
::slotted(sc-divider) {
  margin: 16px 0 !important;
}
sc-line-item ~ sc-line-item {
  margin-top: 14px;
}

.total-price {
  white-space: nowrap;
}
